@font-face {
  font-family: 'OpenSansHebrew Bold';
  font-weight: 200;
  font-display: fallback;
  src: local('OpenSansHebrew Bold'),
    url('../assets/fonts/OpenSansHebrew-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansHebrew BoldItalic';
  font-weight: 200;
  font-display: fallback;
  src: local('OpenSansHebrew BoldItalic'),
    url('../assets/fonts/OpenSansHebrew-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansHebrew ExtraBold';
  font-display: fallback;
  src: local('OpenSansHebrew ExtraBold'),
    url('../assets/fonts/OpenSansHebrew-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansHebrew Regular';
  font-display: fallback;
  src: local('OpenSansHebrew Regular'),
    url('../assets/fonts/OpenSansHebrew-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansHebrew Light';
  font-display: fallback;
  src: local('OpenSansHebrew Light'),
    url('../assets/fonts/OpenSansHebrew-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Gveret Levin AlefAlefAlef Regular';
  font-display: fallback;
  src: local('Gveret Levin AlefAlefAlef Regular'),
    url('../assets/fonts/GveretLevin-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Almoni Tzar Regular';
  font-display: fallback;
  src: local('Almoni Tzar Regular'),
    url('../assets/fonts/almoni-tzar-aaa-regular.otf') format('truetype');
}

@font-face {
  font-family: 'Almoni Tzar Bold';
  font-display: fallback;
  src: local('Almoni Tzar Bold'),
    url('../assets/fonts/almoni-tzar-aaa-bold.otf') format('truetype');
}

@font-face {
  font-family: 'RooneySans Light Italic';
  font-display: fallback;
  src: local('RooneySans Light Italic'),
    url('../assets/fonts/RooneySans-LightItalic.otf') format('truetype');
}

@font-face {
  font-family: 'Horev CLM Heavy';
  font-display: fallback;
  src: local('Horev CLM Heavy'),
    url('../assets/fonts/horev-clm-heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: HWTArtz;
  font-display: fallback;
  src: local('HWTArtz'),
    url('../assets/fonts/HWT-Artz-W00-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Noto Sans Hebrew ExtraCondensed Black';
  font-display: fallback;
  src: local('Noto Sans Hebrew ExtraCondensed Black'),
    url('../assets/fonts/noto-sans-hebrew-extracondensed-black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: ' Noto Sans Hebrew ExtraCondensed Regular';
  font-display: fallback;
  src: local('Noto Sans Hebrew ExtraCondensed Regular'),
    url('../assets/fonts/noto-sans-hebrew-extracondensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
