.swiper {
  flex: 1 !important;
  width: 100%;
}

.swiper-slide {
  height: 90% !important;
}

@media only screen and (min-width: 600px) {
  .swiper-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    gap: 4rem;
  }
  .swiper {
    margin-bottom: 15rem;
    flex: 1;
    overflow: unset;
  }
}
