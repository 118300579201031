*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a:focus:not(:focus-visible),
button:focus:not(:focus-visible),
input:focus:not(:focus-visible),
select:focus:not(:focus-visible),
textarea:focus:not(:focus-visible) {
  outline: none;
}

/* Apply style to the element only when it is focused using the keyboard */
a:focus-visible,
button:focus-visible,
input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  outline: 3px solid blue;
}

html {
  /* 1rem == 10px */
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;

  /* in the future use mediaqueries here to change the %, it will make everything bigger or smaller */
}

body {
  box-sizing: border-box;
  background: var(--color-background);
  height: 100%;
  min-height: stretch;
  display: flex;
  flex-direction: column;
  margin: 0;
}

#root {
  height: 100%;
}

#what-i-do-here {
  direction: rtl;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
  background: unset;
  border: unset;
  outline: unset;
}

h1 {
  margin: 0;
}

a {
  display: block;
  text-decoration: none;
  color: unset;
}

ul {
  list-style: none;
}
